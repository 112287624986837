<template>
  <div class="wrapper">
    <el-card shadow="always">
      <div class="cardHeader">
        <span class="title">离职公告栏 <span class="font">(最近六个月)</span></span>
      </div>
      <el-row v-for="(item, index) in newStffsList" :key="index">
        <el-col>
          <el-row class="vd_bd_content">
            <el-col :md="5" class="vd_con_left">
              <el-row class="vd_left_avatar">
                <el-col>
                  <el-avatar
                    class="vd_avatar_img"
                    shape="square"
                    :size="100"
                    fit="fill"
                    :src="helper.picUrl(item.imge_url, 'l')"
                  ></el-avatar>
                  <!-- <el-image
                  style="width: 100px; height: 100px"
                  :src="helper.picUrl(item.imge_url,'l')"
                  :fit="fit"></el-image> -->
                </el-col>
              </el-row>
            </el-col>
            <el-col :md="18" class="vd_con_right">
              <el-row>
                <el-col>
                  <el-row class="">
                    <el-col :md="4" class="vd_name">
                      {{ item.stff_name }}
                    </el-col>
                    <el-col :md="4" class="vd_icon01" v-if="item.stff_gender === '男'">
                      {{ item.stff_gender }}
                      <i class="el-icon-male"></i>
                    </el-col>
                    <el-col :md="4" class="vd_icon02" v-if="item.stff_gender === '女'">
                      {{ item.stff_gender }}
                      <i class="el-icon-female"></i>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col>
                  <el-row>
                    <el-col class="vd_v">
                      {{ item.cptt_name }}
                      <span class="vd_span">({{ helper.toTimeDay(item.stff_leave_time) }}离职)</span>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col>
                  <el-row>
                    <el-col class="vd_depar vd_sta">
                      <el-tag type="info" size="mini" class="vg_mr_8">
                        <span>部门：{{ item.dept_name }}</span></el-tag
                      >
                      <el-tag type="info" size="mini" class="vg_mr_8">
                        <span v-if="item.dept_team_name">小组：{{ item.dept_team_name }}</span>
                        <span v-else>小组：暂无小组</span>
                      </el-tag>
                      <el-tag type="info" size="mini">
                        <span v-if="item.stff_job">岗位：{{ item.stff_job }}</span>
                        <span v-else>岗位：暂无岗位</span>
                      </el-tag>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col>
                  <el-row>
                    <el-col class="vd_sta">
                      <span class="vd_sta_left">员工简介：</span>
                      <span class="vd_sta_right" v-if="item.stff_intro">{{ item.stff_intro }}</span>
                      <span class="vd_sta_right" v-else>没有任何介绍~</span>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-pagination
        class="vd_pag"
        layout="prev, pager, next"
        @current-change="currtChange"
        :current-page="currentPage"
        :total="totalPage"
        :page-size="5"
        :small="true"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'NewStffsBbd',
  props: {
    newStffsList: Array,
    totalPage: Number
  },
  data() {
    return {
      currentPage: 1,
      newStffs: []
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 向父组件传递分页页码数
    currtChange(val) {
      this.currentPage = val;
      this.$emit('changePageSearch', this.currentPage);
    }
  }
};
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}
.cardHeader {
  border-bottom: 1px solid #ddd;
  margin-bottom: 26px;
  .title {
    color: $color-conventional-text;
  }
}
.vd_bd_content {
  background-color: $color-card-bgc;
  box-shadow: 2px 2px 13px #ccc4c445;
  margin-bottom: 20px;
  padding: 12px;
  .vd_con_left {
    .vd_left_avatar {
      text-align: right;
      margin-right: 30px;
      .vd_avatar_img {
        box-shadow: 2px 2px 13px #ccc4c445;
      }
    }
  }
  .vd_con_right {
    margin-left: 18px;
    .vd_name {
      font-size: 16px;
      margin-bottom: 6px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.6);
    }
    .vd_v {
      font-size: 14px;
      margin-bottom: 4px;
    }
    .vd_sta {
      margin-bottom: 4px;
      font-size: 12px;
      .vd_sta_left {
        color: $color-card-label;
        font-weight: 700;
      }
    }
  }
}
.vd_pag {
  text-align: center;
}
.font {
  font-size: 10px;
  font-style: inherit;
  color: #ccc;
  margin-top: 5px;
}
</style>
