<template>
  <div class="wrapper">
    <el-card shadow="always">
      <div class="vd_cardHeader">
        <span class="vd_box_three">
          <el-row>
            <el-col :md="14"><span class="vd_title">假期信息</span></el-col>
            <el-col :md="10" style="text-align: right">
              <span class="box_title"><i class="el-icon-info"></i> 最近更新时间：</span>
              <span class="box_name">{{ latestUpdateTime }}</span>
              <span class="vd_cen">（通过）</span>
            </el-col>
            <!-- <el-col :md="7" class="vd_cen">(以人事收到假单时间为准)</el-col>-->
          </el-row>
        </span>
      </div>
      <el-row>
        <el-col class="vd_box-one">
          <el-row>
            <el-col :md="4" class="vd_tag">
              <el-tag effect="plain" size="small" type="">年假假期</el-tag>
            </el-col>
            <el-col :md="20">
              <el-row class="vd_break_time">
                <el-col :md="16" class="vd_time_box">
                  <span class="vd_time_name">{{ thisYear - 1 }}-01-01 </span>
                  <span class="vd_time_title vd_title_st">(起始日期) </span>
                  <span><i class="el-icon-minus"></i></span>
                  <span class="vd_time_name"> {{ thisYear }}-03-31 </span>
                  <span class="vd_time_title vd_title_en">(结束日期)</span>
                </el-col>
                <el-col :md="6" class="vd_time_box vd_times_box">
                  <span class="vd_time_title">剩余 </span>
                  <span class="vd_leave_times" v-if="persForm.stff_last_break > 0">{{ persForm.stff_last_break }}</span>
                  <span class="vd_leave_times_red" v-else>0</span>
                  <span class="vd_time_title"> 天</span>
                  <span> / </span>
                  <span class="limit_times">{{ persForm.stff_last_break_limit }}</span>
                  <span class="vd_time_title"> 天</span>
                </el-col>
              </el-row>
              <el-row class="vd_break_time">
                <el-col :md="16" class="vd_time_box">
                  <span class="vd_time_name" v-if="persForm.stff_this_break_time === 0">{{ thisYear }}-01-01 </span>
                  <span class="vd_time_name" v-else>{{ thisBreak }}</span>
                  <span class="vd_time_title vd_title_st">(起始日期) </span>
                  <span><i class="el-icon-minus"></i></span>
                  <span class="vd_time_name"> {{ thisYear + 1 }}-03-31 </span>
                  <span class="vd_time_title vd_title_en">(结束日期)</span>
                </el-col>
                <el-col :md="6" class="vd_time_box vd_times_box">
                  <span class="vd_time_title">剩余 </span>
                  <span class="vd_leave_times" v-if="persForm.stff_this_break > 0">{{ persForm.stff_this_break }}</span>
                  <span class="vd_leave_times_red" v-else>0</span>
                  <span class="vd_time_title"> 天</span>
                  <span> / </span>
                  <span class="limit_times">{{ persForm.stff_this_break_limit }}</span>
                  <span class="vd_time_title"> 天</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col class="vd_box-three">
          <el-col :md="4" class="vd_tag">
            <el-tag effect="plain" size="small" type="">旅游假期</el-tag>
          </el-col>
          <el-col :md="20">
            <el-row class="vd_break_time">
              <el-col :md="16" class="vd_time_box">
                <div v-if="persForm.stff_tour_break_time">
                  <span class="vd_time_name"
                    >{{ helper.toTimeDay(persForm.stff_tour_break_time) }}
                  </span>
                  <span class="vd_time_title vd_title_st">(起始日期) </span>
                  <span><i class="el-icon-minus"></i></span>
                  <span class="vd_time_name">
                    {{
                      helper.toTimeYear(persForm.stff_tour_break_time) + 1
                    }}-12-31
                  </span>
                  <span class="vd_time_title vd_title_en">(结束日期)</span>
                </div>
                <div v-else>
                  <div v-if="thisYear % 2 === 0">
                    <span class="vd_time_name">{{ thisYear - 1 }}-01-01 </span>
                    <span class="vd_time_title vd_title_st">(起始日期) </span>
                    <span><i class="el-icon-minus"></i></span>
                    <span class="vd_time_name"> {{ thisYear }}-12-31 </span>
                    <span class="vd_time_title vd_title_en">(结束日期)</span>
                  </div>
                  <div v-else>
                    <span class="vd_time_name">{{ thisYear }}-01-01</span>
                    <span class="vd_time_title vd_title_st">(起始日期) </span>
                    <span><i class="el-icon-minus"></i></span>
                    <span class="vd_time_name"> {{ thisYear + 1 }}-12-31 </span>
                    <span class="vd_time_title vd_title_en">(结束日期)</span>
                  </div>
                </div>
              </el-col>
              <el-col :md="6" class="vd_time_box vd_times_box">
                <span class="vd_time_title">剩余 </span>
                <span
                  class="vd_leave_times"
                  v-if="persForm.stff_tour_break > 0"
                  >{{ persForm.stff_tour_break }}</span
                >
                <span class="vd_leave_times_red" v-else>0</span>
                <span class="vd_time_title"> 天</span>
                <span> / </span>
                <span class="limit_times">{{
                  persForm.stff_tour_break > 0 ? 2 : 0
                }}</span>
                <span class="vd_time_title"> 天</span>
              </el-col>
            </el-row>
          </el-col>
        </el-col> -->
        <el-col class="vd_box-two">
          <el-row style="display: flex; align-items: center">
            <el-col :md="4" class="vd_tag tag_label">
              <el-tag effect="plain" size="small" type="">调休时间</el-tag>
            </el-col>
            <el-col :md="12" class="vd_time_box">
              <span class="vd_time_title">剩余 </span>
              <span class="vd_leave_times">{{ !persForm.stff_off_hour ? 0 : persForm.stff_off_hour }}</span>
              <span> 小时</span>
            </el-col>
            <el-col :md="8" class="vd_time_box" style="text-align: center">
              <el-button type="success" size="small" @click="addPerson()">申请假单</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div>
        <div style="margin-top: 10px; height: 100%">
          <el-collapse-transition>
            <div v-show="show">
              <el-divider></el-divider>
              <!-- <div>个人假单记录</div> -->
              <el-card>
                <el-form :model="searchForm" :inline="true">
                  <el-row>
                    <el-col :md="24">
                      <el-form-item label="请假类型：">
                        <el-select v-model="searchForm.leav_type" placeholder="请选择请假类型" clearable size="small">
                          <el-option v-for="item in leavTypeGroupOpt" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :md="24">
                      <el-form-item label="录入时间：">
                        <div class="block vg_mr_32">
                          <el-date-picker
                            size="small"
                            v-model="searchForm.timeValue"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                          >
                          </el-date-picker>
                        </div>
                      </el-form-item>
                      <el-form-item>
                        <el-button class="vg_ml_16" icon="el-icon-search" size="small" type="primary" @click="clickSearchForm()">查询</el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <el-table
                  ref="multiTable"
                  :data="tableData"
                  border
                  style="width: 100%"
                  v-loading="loading"
                  class="vg_mt_16 vg_pointer"
                  @row-dblclick="dbClickJp"
                >
                  <el-table-column label="请假类型" prop="lave_type">
                    <template slot-scope="scope">
                      <div>{{ formatLeavType(scope.row) }}</div>
                    </template>
                  </el-table-column>

                  <el-table-column label="开始日期" prop="leav_start_time" :formatter="formatDate" />
                  <el-table-column label="请假时长" prop="formatLeavType" :formatter="formatLeavDate" :show-overflow-tooltip="true" />
                  <el-table-column label="录入时间" prop="create_time" :formatter="formatUpdate" :show-overflow-tooltip="true" />
                  <el-table-column label="在批状态" align="center">
                    <template slot-scope="scope">
                      <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{ helper.getStatusName(scope.row.status).name }}</el-tag>
                    </template>
                  </el-table-column>
                </el-table>
                <pubPagination :totalPage="total" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
              </el-card>
            </div>
          </el-collapse-transition>
        </div>
      </div>

      <div>
        <el-row>
          <el-col :md="8" :offset="8">
            <div style="text-align: center" :class="{ vg_mt_16: show }">
              <div v-if="!show">查看记录</div>
              <div v-else>收起记录</div>
              <i :class="{ 'el-icon-arrow-down ': show === false, 'el-icon-arrow-up': show, 'class-name': !show }" class="vg_pointer" @click="show = !show"></i>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
import { get } from '@api/request';
import { leavAPI } from '@/api/modules/leav';
import options from '@/views/component/common/options';
import pubPagination from '@/components/common/pubPagination';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  components: {
    pubPagination
  },
  name: 'PersBreakPlan',
  props: {
    persForm: Object,
    thisBreak: String
  },
  data() {
    return {
      thisYear: '',
      latestUpdateTime: null,
      show: false,
      searchForm: {
        page_no: 1,
        leav_type: null,
        timeValue: {
          startTime: null,
          endTime: null
        }
      },
      total: 0,
      leavTypeGroupOpt: [], //请假类型
      tableData: [],
      loading: false
    };
  },
  created() {
    this.leavTypeGroupOpt = options.leavOpts; //请假类型
    this.getLeavsList();
  },
  mounted() {
    this.getLocalYear();
    this.getLatestUpdateTime();
  },
  methods: {
    // 获取本地时间今年年份
    getLocalYear() {
      let date = new Date();
      let y = date.getFullYear();
      this.thisYear = y;
    },
    //获取最新更新时间
    getLatestUpdateTime() {
      get(leavAPI.getLatestUpdateTime)
        .then(res => {
          if (res.data.code === 0) {
            this.latestUpdateTime = this.helper.toTimeDay(res.data.data);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    addPerson() {
      // this.enRouter('/leav_add','perm_id:109')
      this.$router.push({
        path: '/leav_add',
        query: {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 109,
              personal: 1
            })
          )
        }
      });
    },

    //获取请假信息列表
    getLeavsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      const searchBody = {};
      searchBody.start_time = timeNewVal.startTime;
      searchBody.end_time = timeNewVal.endTime;
      searchBody.leav_type = this.searchForm.leav_type;
      searchBody.page_no = this.searchForm.page_no;
      get(leavAPI.getMyLeavs, searchBody).then(res => {
        if (res.data.code === 0) {
          this.loading = false;
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    //查询按钮
    clickSearchForm() {
      this.$refs.pubPagination.currentPage = 1;
      this.searchForm.page_no = 1;
      this.getLeavsList();
    },
    //录入时间
    formatUpdate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 请假类型
    formatLeavType(row) {
      if (row.leav_type === 0) {
        return '增加调休';
      } else if (row.leav_type === 1) {
        return '调休';
      } else if (row.leav_type === 2) {
        return '年假';
      } else if (row.leav_type === 3) {
        return '事假';
      } else if (row.leav_type === 4) {
        return '病假';
      } else if (row.leav_type === 5) {
        return '产假';
      } else if (row.leav_type === 6) {
        return '婚假';
      } else if (row.leav_type === 7) {
        return '丧假';
      } else if (row.leav_type === 8) {
        return '工伤';
      } else if (row.leav_type === 9) {
        return '陪产';
      } else if (row.leav_type === 10) {
        return '旅游假';
      } else if (row.leav_type === 11) {
        return '产检假';
      }
    },
    //请假时长
    formatLeavDate(row) {
      if (row.leav_type === 0) {
        const leavDate = row.leav_hour_diff + '小时' + '(添加调休)';
        return leavDate;
      } else if (row.leav_type === 1) {
        const leavDate = row.leav_hour_diff + '小时';
        return leavDate;
      } else {
        if (row.leav_hour_diff) {
          const leavDate = row.leav_hour_diff + '小时';
          return leavDate;
        } else {
          const leavDate = row.leav_day_diff + '天';
          return leavDate;
        }
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDay(row.leav_start_date);
    },
    //个人请假信息 页面改变
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getLeavsList();
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/leav_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: 109,
            form_id: row.leav_id
          })
        )
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_cardHeader {
  border-bottom: 1px solid #ddd;
  margin-bottom: 26px;
  .vd_title {
    color: $color-conventional-text;
  }
}
.vd_tag {
  text-align: center;
  padding: 12px;
}
.vd_tag ::v-deep .el-tag--dark {
  //background-color: #40b544;
}
.vd_box-one {
  margin-bottom: 12px;
}
.vd_break_time {
  .vd_time_box {
    padding: 12px;
    border-bottom: 1px dashed #ddd;
  }
  .vd_times_box {
    text-align: center;
  }
  .vd_time_name {
    font-size: 16px;
  }
  .vd_time_title {
    font-size: 12px;
    color: $color-time-title;
  }
  .vd_title_st {
    color: $color-title-start;
    font-weight: 600;
  }
  .vd_title_en {
    color: rgba(239, 67, 54, 0.69);
    font-weight: 600;
  }
}
.vd_box-two {
  .vd_time_box {
    padding: 12px;
    color: $color-time-box;
    font-size: 12px;
  }
  .vd_update_box {
    text-align: center;
  }
}
.vd_box_three {
  .box_title {
    font-size: 12px;
    //color: #009688;
  }
}
.vd_leave_times {
  font-size: 16px;
  //color: #009688;
  color: $color-primary;
  font-weight: 700;
}
.vd_leave_times_red {
  font-size: 16px;
  color: $color-danger;
  font-weight: 700;
}
.el-icon-info {
  color: $color-icon-info;
}
.vd_opc {
  opacity: 0;
}
.vd_cen {
  text-align: right;
  font-size: 12px;
  color: #a99e9e;
  font-weight: 600;
}

.box_name {
  color: #ff9800;
  font-weight: 600;
}
/* 上下跳动特效 */
.class-name {
  -webkit-animation: bounce-down 1s linear infinite;
  animation: bounce-down 1s linear infinite;
}
@-webkit-keyframes bounce-down {
  25% {
    -webkit-transform: translateY(-5px);
  }
  50%,
  100% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(5px);
  }
}
@keyframes bounce-down {
  25% {
    transform: translateY(-5px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(5px);
  }
}
</style>
