import topic from '../topic';
export const leavAPI = {
  getLeavs: topic.leavTopic + '/get_leavs',
  addLeav: topic.leavTopic + '/add_leav',
  editLeav: topic.leavTopic + '/edit_leav',
  getLeavById: topic.leavTopic + '/get_leav_by_id',
  deleteLeavByIds: topic.leavTopic + '/delete_leav_by_ids',
  getLatestUpdateTime: topic.leavTopic + '/get_latest_update_time',
  copyLeavs: topic.leavTopic + '/copy_leavs',
  exportLeavListV1: topic.leavTopic + '/export_leav_list_v1',
  getLeavApprUserIds: topic.leavTopic + '/get_leav_appr_user_ids',
  getMyLeavs: topic.leavTopic + '/get_my_leavs'
};
