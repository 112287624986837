<template>
  <div class="vg_wrapper">
    <el-row :gutter="24">
      <el-col :md="12">
        <el-row>
          <el-col class="vd_model_one">
            <PersInfoPlan :persForm="persForm" :circleUrl="circleUrl"></PersInfoPlan>
          </el-col>
          <el-col class="vd_model_two">
            <PersBreakPlan :persForm="persForm" :thisBreak="thisBreak"></PersBreakPlan>
          </el-col>
        </el-row>
      </el-col>
      <el-col :md="12">
        <el-row>
          <el-col>
            <NewStffsBbd
              :newStffsList.sync="newStffsList"
              :totalPage="totalPage"
              @changePageSearch="changePageSearch"
              v-loading="loading"
            ></NewStffsBbd>
            <LeaveStffsBbd
              class="vd_mart"
              :newStffsList.sync="leaveStffsList"
              :totalPage="totalPageL"
              @changePageSearch="changePageSearchL"
              v-loading="loadingL"
            ></LeaveStffsBbd>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PersInfoPlan from '@/views/OfficeManagement/PersManage/PersModel/PersInfoPlan';
import PersBreakPlan from '@/views/OfficeManagement/PersManage/PersModel/PersBreakPlan';
import NewStffsBbd from '@/views/OfficeManagement/PersManage/OtherModel/NewStffsBbd';
import LeaveStffsBbd from '@/views/OfficeManagement/PersManage/OtherModel/LeaveStffsBbd';
import { get } from '@api/request';
import { stffAPI } from '@api/modules/staff';
export default {
  name: 'PersList',
  components: {
    PersInfoPlan,
    PersBreakPlan,
    NewStffsBbd,
    LeaveStffsBbd
  },
  data() {
    return {
      persForm: {},
      circleUrl: '',
      thisBreak: '',
      newStffsList: [],
      totalPage: 0, //数据总条数
      currentPage: 1,
      loading: true,
      leaveStffsList: [],
      totalPageL: 0, //数据总条数
      currentPageL: 1,
      loadingL: true,
      newFir: [],
      newSec: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getPersForm();
      this.getTenNewStffs();
      this.getTenLeaveStffs();
    },
    //获取个人信息
    getPersForm() {
      get(stffAPI.getMyStff)
        .then(res => {
          // console.log(res);
          if (res.data.code === 0) {
            this.persForm = res.data.data.form;
            this.thisBreak = this.helper.toTimeDay(this.persForm.stff_this_break_time);
            if (this.persForm.imge_url !== null) {
              this.circleUrl = this.helper.picUrl(this.persForm.imge_url, 'm');
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 分页查询（挂载loading）
    changePageSearch(val) {
      this.currentPage = val;
      this.loading = true;
      setTimeout(() => {
        this.getTenNewStffs();
      }, 1000);
    },
    // 分页查询（挂载loading）
    changePageSearchL(val) {
      this.currentPageL = val;
      this.loadingL = true;
      setTimeout(() => {
        this.getTenLeaveStffs();
      }, 1000);
    },
    // 获取10个最新的员工数据
    getTenNewStffs() {
      get(stffAPI.getNewStffs, { page_no: this.currentPage }).then(res => {
        if (res.data.code === 0) {
          this.loading = false;
          this.newStffsList = res.data.data.list;
          this.totalPage = res.data.data.total;
        }
      });
    },
    // 获取离职的员工数据
    getTenLeaveStffs() {
      get(stffAPI.getLeaveStffs, { page_no: this.currentPageL }).then(res => {
        if (res.data.code === 0) {
          this.loadingL = false;
          this.leaveStffsList = res.data.data.list;
          this.totalPageL = res.data.data.total;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_model_one {
  margin-bottom: 26px;
}
.vd_mart {
  margin-top: 30px;
}
</style>
