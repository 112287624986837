<template>
  <div class="wrapper">
    <el-card shadow="always">
      <div class="vd_cardHeader">
        <span class="vd_title">个人信息</span>
      </div>
      <el-row class="vd_jc">
        <el-col :md="4">
          <div class="demo-basic--circle" style="text-align: center">
            <div class="block">
              <el-image
                style="width: 100px; height: 100px; border-radius: 50%; display: flex; justify-content: center; align-items: center"
                :src="circleUrl"
              >
                <div slot="error" class="vd_image_slot">
                  <i class="el-icon-s-custom"></i>
                </div>
              </el-image>
            </div>
          </div>
        </el-col>
        <el-col :md="18" class="vd_con_right">
          <el-row class="vd_headCard_one">
            <el-col :md="3" class="vd_headCard vd_name">
              {{ persForm.stff_name }}
            </el-col>
            <el-col :md="4" class="vd_icon01" v-if="persForm.stff_gender === '男'">
              {{ persForm.stff_gender }}
              <i class="el-icon-male"></i>
            </el-col>
            <el-col :md="4" class="vd_icon02" v-if="persForm.stff_gender === '女'">
              {{ persForm.stff_gender }}
              <i class="el-icon-female"></i>
            </el-col>
          </el-row>
          <el-row class="vd_headCard_two">
            <el-col>
              {{ persForm.cptt_name }}
              <span class="vd_two_time">({{ helper.toTimeDay(persForm.stff_enter_time) }}入职)</span>
            </el-col>
          </el-row>
          <el-row class="vd_headCard_three">
            <el-tag type="info" size="mini">
              <span>部门：{{ persForm.dept_name }}</span></el-tag
            >
            <el-tag type="info" size="mini">
              <span>小组：{{ persForm.dept_team_name }}</span></el-tag
            >
            <el-tag type="info" size="mini">
              <span>岗位：{{ persForm.stff_job }}</span></el-tag
            >
          </el-row>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'PersInfoPlan',
  props: {
    persForm: Object,
    circleUrl: String
  },
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.vd_cardHeader {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  .vd_title {
    color: $color-conventional-text;
  }
}
.vd_headCard_one {
  display: flex;
  align-items: flex-end;
  .vd_icon01 {
    i {
      font-size: 16px;
      color: $color-male;
      font-weight: 700;
    }
  }
  .vd_icon02 {
    i {
      font-size: 16px;
      color: $color-female;
      font-weight: 700;
    }
  }
}
.vd_headCard_two {
  margin: 6px 0;
  .vd_two_time {
    font-size: 12px;
  }
}
.vd_headCard_three {
  .el-tag {
    margin-right: 6px;
  }
}
.vd_headCard {
  font-weight: bold;
  color: $color-conventional-text;
}
.vd_jc {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.vd_name {
  font-size: 18px;
  color: $color-conventional-text;
}
.block {
  .el-avatar {
    box-shadow: 0 2px 8px rgb(0 0 0 / 28%);
  }
}
.el-card {
  color: $color-card-text;
}
.el-icon-s-custom {
  font-size: 66px;
}
.vd_con_right {
  margin-left: 5px;
}
</style>
