const educOpts = [
  {
    label: '小学',
    value: '小学'
  },
  {
    label: '初中',
    value: '初中'
  },
  {
    label: '高中',
    value: '高中'
  },
  {
    label: '大专',
    value: '大专'
  },
  {
    label: '本科',
    value: '本科'
  },
  {
    label: '研究生',
    value: '研究生'
  },
  {
    label: '博士',
    value: '博士'
  }
];

const marrOpts = [
  {
    label: '未婚未育',
    value: '未婚未育'
  },
  {
    label: '已婚未育',
    value: '已婚未育'
  },
  {
    label: '已婚已育',
    value: '已婚已育'
  },
  {
    label: '离异',
    value: '离异'
  },
  {
    label: '其他',
    value: '其他'
  }
];

const politicOpts = [
  {
    label: '群众',
    value: '群众'
  },
  {
    label: '团员',
    value: '团员'
  },
  {
    label: '党员',
    value: '党员'
  },
  {
    label: '民族党派',
    value: '民族党派'
  },
  {
    label: '其他',
    value: '其他'
  }
];

const handBookOpts = [
  {
    label: '已交',
    value: '已交'
  },
  {
    label: '未交',
    value: '未交'
  },
  {
    label: '来沪',
    value: '来沪'
  }
];

const bloodOpts = [
  {
    label: 'A',
    value: 'A'
  },
  {
    label: 'B',
    value: 'B'
  },
  {
    label: 'O',
    value: 'O'
  },
  {
    label: 'AB',
    value: 'AB'
  },
  {
    label: '其他',
    value: '其他'
  }
];

const labOpts = [
  {
    label: '劳动合同',
    value: 4
  },
  {
    label: '返聘协议',
    value: 1
  },
  {
    label: '实习协议',
    value: 2
  },
  {
    label: '劳务协议',
    value: 3
  },
  {
    label: '兼职协议',
    value: 6
  },
  {
    label: '无需合同',
    value: 7
  }
];

const signOpts = [
  {
    label: '第一签',
    value: '第一签'
  },
  {
    label: '第二签',
    value: '第二签'
  },
  {
    label: '第三签',
    value: '第三签'
  }
];

const leavOpts = [
  {
    label: '增加调休',
    value: 0
  },
  {
    label: '使用调休',
    value: 1
  },
  {
    label: '年假',
    value: 2
  },
  {
    label: '事假',
    value: 3
  },
  {
    label: '病假',
    value: 4
  },
  {
    label: '旅游假',
    value: 10
  },
  {
    label: '产假',
    value: 5
  },
  {
    label: '婚假',
    value: 6
  },
  {
    label: '丧假',
    value: 7
  },
  {
    label: '工伤',
    value: 8
  },
  {
    label: '陪产',
    value: 9
  },
  {
    label: '产检假',
    value: 11
  },
  {
    label: '本市外勤(上海)',
    value: 12
  },
  {
    label: '外省市出差',
    value: 13
  },
  {
    label: '外省市外勤',
    value: 14
  }
];

export default {
  educOpts,
  marrOpts,
  politicOpts,
  handBookOpts,
  bloodOpts,
  labOpts,
  signOpts,
  leavOpts
};
